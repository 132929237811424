var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.layout,{tag:"div"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t('control_region.name')},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.doGet(1)}},model:{value:(_vm.search_info.name),callback:function ($$v) {_vm.$set(_vm.search_info, "name", $$v)},expression:"search_info.name"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"large":"","color":"secondary2"},on:{"click":function($event){return _vm.doGet(1)}}},[_vm._v(_vm._s(_vm.$t('search')))])],1)],1),_c('table-list',{attrs:{"list":_vm.list,"listCount":_vm.list_count},on:{"change":_vm.changePagination},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('i',{staticClass:"airport icon-guanzhiqu icon_font"})]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"margin-bottom":"10px"}},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"small_font"},[_vm._v(_vm._s(_vm.$t('control_region.min_vertical'))+": "+_vm._s(item.min_vertical))]),_c('div',{staticClass:"small_font"},[_vm._v(_vm._s(_vm.$t('control_region.max_vertical'))+": "+_vm._s(item.max_vertical))])]}},{key:"active",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary","outlined":"","text":"","x-small":"","fab":""},on:{"click":function($event){return _vm.edit_open(item)}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"airport small-font icon-bianji ",staticStyle:{"font-size":"18px !important","color":"#b5bac1"}})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('edit')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"secondary","text":"","outlined":"","to":{name: 'controlSector', params: {id: item.id,info_id:_vm.info.flight_information_region}},"depressed":"","x-small":"","fab":""}},'v-btn',attrs,false),on),[_c('i',{staticClass:"airport small-font icon-shanquguanli",staticStyle:{"font-size":"18px !important","color":"#b5bac1"}})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('control_region.control_sector')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"secondary","text":"","outlined":"","to":{name:'controlRegionPreview',params:{id:_vm.info.flight_information_region}},"depressed":"","x-small":"","fab":""}},'v-btn',attrs,false),on),[_c('i',{staticClass:"airport small-font icon-preview",staticStyle:{"font-size":"18px !important","color":"#b5bac1"}})])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('preview')))])])]}}])})],1),_c('v-btn',{staticClass:"float-btn",attrs:{"color":"primary","fab":""},on:{"click":_vm.add_open}},[_c('v-icon',[_vm._v("mdi-plus")])],1),_c('v-dialog',{attrs:{"width":"800px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"color":"secondary"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t(_vm.edit_mode ? 'control_region.edit_action' : 'control_region.add_action')))]),_c('v-container',{staticClass:"pd-30"},[_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"label":_vm.$t('control_region.name')},model:{value:(_vm.info.name),callback:function ($$v) {_vm.$set(_vm.info, "name", $$v)},expression:"info.name"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('control_region.min_vertical')},model:{value:(_vm.info.min_vertical),callback:function ($$v) {_vm.$set(_vm.info, "min_vertical", $$v)},expression:"info.min_vertical"}})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-text-field',{attrs:{"type":"number","label":_vm.$t('control_region.max_vertical')},model:{value:(_vm.info.max_vertical),callback:function ($$v) {_vm.$set(_vm.info, "max_vertical", $$v)},expression:"info.max_vertical"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t('confirm')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }