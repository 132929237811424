import { request } from '@/utils/'

// 获取管制区列表
export const get_list = params => request.get('/api/control_region/',  { params })

// 添加管制区
export const add_info = data => request.post('/api/control_region/', data)

// 修改管制区
export const edit_info = data => request.patch(`/api/control_region/${data.id}/`, data)
